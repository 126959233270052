import { CloseButton, Collapse, Flex, Stack, Text } from '@chakra-ui/react'

import {
  isBrave,
  isChrome,
  isMobileDevice,
  isDesktopDevice,
  isSafari,
  isRobot,
} from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

const displayText = [
  {
    header: 'Gamma works best on Chrome desktop.',
    text: 'Try opening this page on your computer for the full Gamma experience.',
  },
  {
    header: 'Gamma works best on Chrome.',
    text: 'For the best experience, open this page in Chrome.',
  },
][isDesktopDevice ? 1 : 0]

const isWindowDefined = typeof window !== 'undefined'

export const PreferredBrowserTopbar = () => {
  const [hasLastDismissedLocal, setHasLastDismissedLocal] = useLocalStorage<
    boolean | string
  >(USER_SETTINGS_CONSTANTS.lastDismissedPreferredBrowserMessage, false)

  const onClose = () => {
    setHasLastDismissedLocal(String(new Date()))
  }

  if (!useSSRMounted()) return null // SSR Hydration

  const isUnsupportedBrowser = isBrave() || (!isChrome && !isSafari)
  const isTabletOrUnsupportedDesktopBrowser =
    !isMobileDevice && (!isDesktopDevice || isUnsupportedBrowser)
  const shouldDisplay = !isRobot && isTabletOrUnsupportedDesktopBrowser // show for tablets and unsupported desktop browsers

  return (
    <Flex
      data-testid="preferred-browser-topbar"
      direction="column"
      flex={1}
      top={0}
      pos={'relative'}
      zIndex={'banner'}
    >
      {isWindowDefined && (
        <Collapse in={shouldDisplay && !hasLastDismissedLocal} unmountOnExit>
          <Flex
            bg="gradient.blue-to-orange"
            color="white"
            p={3}
            fontSize={['md', 'sm']}
            textAlign={{ base: 'left', md: 'center' }}
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={0}
              justify={{ base: 'space-between', md: 'center' }}
              flex={1}
              align={{ base: 'flex-start', md: 'center' }}
            >
              <Text fontWeight="bold">
                {displayText.header}
                &nbsp;
              </Text>
              <Text>{displayText.text}</Text>
            </Stack>
            <CloseButton
              onClick={onClose}
              alignSelf="flex-start"
              position="absolute"
              top={2}
              right={2}
            />
          </Flex>
        </Collapse>
      )}
    </Flex>
  )
}
