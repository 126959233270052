// 1. Import `extendTheme`
import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'

// 2. Call `extendTheme` and pass your custom values
export const overrides: ThemeOverride = {
  colors: gammaTheme.colors,
  zIndices: {
    tooltip: 5600, // Force this to be higher than the toast manager
    modal: 1800, // Force this to go over popovers
    dropdown: 1600, // Force this to go over popovers
  },
  fonts: {
    body: "'Eudoxus Sans', sans-serif",
    heading: "'p22-mackinac-pro', sans-serif",
  },
  fontSizes: {
    xxs: '11px',
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: 'none',
      },
    },
    Heading: {
      baseStyle: {
        letterSpacing: '-0.04em',
      },
      variants: {
        fancy: {
          backgroundImage: gammaTheme.colors.gradient['blue-to-purple'],
          backgroundClip: 'text',
          color: 'transparent',
        },
      },
    },
  },
}

export const marketingTheme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: 'trueblue' })
)
